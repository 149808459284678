import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import "../ReportCatalog/AddReport/AddReport.scss";
import "../ContactSupport/ContactSupport.scss";
import { payStubActions } from "../../redux/actions/paystub.actions";
import { useState } from "react";
import { selectPaystubLoading } from "../../redux/selectors/paystub.selector";

function PayStubComponent() {
  const dispatch = useDispatch();

  const [employeeId,setEmployeeId ]= useState<number|undefined>(undefined);
  const paystubSubmitLoading = useSelector(selectPaystubLoading);




  const handleOnChange = (evt: any) => {
    if (/^\d*$/.test(evt.target.value)) {
    setEmployeeId(evt.target.value);
    }
  }


  const handleSubmit=(e:any)=>{
    e.preventDefault();
    console.log('submit')
    if(employeeId!=undefined&&employeeId>0){
      dispatch(
        payStubActions.getPayStub(employeeId)
      );
    }
    else{
      return;
    }
  }

  return (
    <div id="contact-page" data-testid="contact-page">
    
    <div className="contact-body"  id="contact-page">
      <Grid container>
        <Grid item md={7} xs={12}>
        <div className="p-25">
          <div className="form-title">Pay Stub</div>
            <div className="report-form">

            <form
            id="report-form"
            onSubmit={handleSubmit}
          >
              <div className="form-container">
                  
                    <div className="select-lable">Employee Id<span className="mandatory"> *</span></div>
                    <div className="control-cell">
                      <div>
                        <input
                          placeholder={"Enter Employee Id"}
                          value={employeeId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                </div>
              <div className="dialog-footer">
                <Button
                  data-testid="btn-submit"
                  type="submit"
                  className="btn-save"
                  disabled={paystubSubmitLoading}
                >
                  {paystubSubmitLoading ? (
                    <div>
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    <div onClick={handleSubmit}>Export</div>
                  )}
                </Button>
              </div>
          </form>
        </div>
        </div>
        </Grid>
        <Grid item xs={0} md={5} className="help-section"></Grid>
      </Grid>
    
</div>
    </div>
  );
}
export default PayStubComponent;
