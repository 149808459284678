import { Grid, Typography } from "@mui/material";
import PayStubComponent from "./PayStubComponent";

import './paystub.css';

function PayStubPage() {

  return (
    <div id="contactus-container" data-testid="contactus-container">
      <Grid container spacing={0} className="sub-header">
          <Typography>Pay Stub</Typography>
      </Grid>
      <PayStubComponent />
     
    </div>
  );
}
export default PayStubPage;
