import { Dispatch } from "react"
import { ContactForm } from "../models/contactForm"
import { INotificationType } from "../models/notificationType";
import { payStubService } from "../services/paystub.service"
import { IBaseAction } from "./base.action"
import { NotificationActions } from "./notification.actions";

// DEFINE THE ACTION INTERFACES

interface IGetPayStubAction extends IBaseAction {
    readonly type: 'PAYSTUB_GET_PAYSTUB_REQUEST' | 'PAYSTUB_GET_PAYSTUB_SUCCESS' | 'PAYSTUB_GET_PAYSTUB_FAILURE';
    response?: any
}




// EXPORT THE ACTION INTERFACES FOR REDUCER CONSUMPTION

export type PayStubActions = IGetPayStubAction



// DEFINE THE ACTIONS

const getPayStub = (employeeId:number) => {
    return function (dispatch: Dispatch<IGetPayStubAction| NotificationActions>) {
        dispatch({ type: 'PAYSTUB_GET_PAYSTUB_REQUEST' });
        payStubService.getPayStub(employeeId).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `File downloaded successfully.`, stayVisible: false })
            return dispatch({ type: 'PAYSTUB_GET_PAYSTUB_SUCCESS', response: response })
        }, (error) => {
            console.log(error)
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Unauthorized, message: `${error==401?"You are not authorised to access this feature.":error==404?"Record Not Found":error}`, stayVisible: false })
            // return dispatch({ type: 'PAYSTUB_GET_PAYSTUB_FAILURE', error })
        })
    }
}



// EXPORT THE ACTIONS FOR COMPONENT CONSUMPTION

export const payStubActions = {
    getPayStub
}