import appConfig from "../../utils/appConfig";
import { FetchUtils } from "../../utils/fetch.utils";


const apiBaseUrl = appConfig.apiBaseUrl;
const paystubApi = 'paystub';


const getPayStub = (employeeId:number): Promise<any> => {
    const URL = `${apiBaseUrl}/${paystubApi}/GetPayStubData?empId=${employeeId}`;
    let response = FetchUtils.GETFile(URL);
    return response;
};



export const payStubService = {
    getPayStub
};
