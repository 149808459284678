import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

import NotFoundPage from "../components/StatusPages/NotFound/NotFoundPage";
import ReportCatalogPage from "../components/ReportCatalog/ReportCatalogPage";
import DashboardPage from "../components/Dashboard/DashboardPage";
import Logout from "../components/Logout/Logout";
import LoginPage from "../components/LoginPage/LoginPage";
import ViewReportPage from "../components/ViewReport/ViewReportPage";
import SettingsForm from "../components/SettingsForm/SettingsForm";
import ReportForm from "../components/ReportForm/ReportForm";
import SubscriptionWizard from "../components/SubscriptionWizard/SubscriptionWizard";
import FileStoragePage from "../components/FileStorage/FileStoragePage";
import Download from "../components/Download/Download";
import AdminSubscriptions from "../components/AdminSubscriptions/AdminSubscriptions";
import ForbiddenPage from "../components/StatusPages/Forbidden/ForbiddenPage";
import UserManagementPage from "../components/UserManagement/UserManagementPage";
import ContactPage from "../components/ContactSupport/ContactPage";
import HomePage from "../components/HomePage/HomePage";
import Alerts from "../components/Alerts/Alerts";
import { User } from "../redux/models/user";
import MySubscriptions from "../components/MySubscriptions/MySubscriptions";
import RunReportPage from "../components/RunReport/RunReportPage";
import PayStubPage from "../components/PayStub/PayStubPage";

interface IRoutesStatus{
    inProgress: string, 
    isAuthenticated: boolean, 
    user?: User
}


export const MainRoutes = ({inProgress, isAuthenticated, user}: IRoutesStatus)=>{
  const valid = inProgress !== "none" ? null : isAuthenticated && user?.isValid;
    return (
        <Switch>
             <Route exact path="/">
            {valid ? (
               <HomePage />
            ) : (
              <LoginPage />
            )}
          </Route>
          <PrivateRoute exact path="/paystub">
            <PayStubPage />
          </PrivateRoute>
          <Route exact path="/support">
            <ContactPage />
          </Route>

          <PrivateRoute path="/alerts">
            <Alerts />
          </PrivateRoute>

          <PrivateRoute path="/favorites/:favorites?">
            <HomePage />
          </PrivateRoute>

          <PrivateRoute path="/mydashboard/:id?">
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute path="/reports/catalog/:categoryId?">
            <ReportCatalogPage />
          </PrivateRoute>
          <PrivateRoute path="/reports/storage/download/:id">
            <Download />
          </PrivateRoute>
          <PrivateRoute path="/reports/storage/:sid">
            <FileStoragePage />
          </PrivateRoute>
          <PrivateRoute path="/reports/storage/:sharelink">
            <FileStoragePage />
          </PrivateRoute>
          <PrivateRoute path="/reports/storage">
            <FileStoragePage />
          </PrivateRoute>
          <PrivateRoute path="/reports/subscription/:id/:sid/:filter">
            <SubscriptionWizard action="edit" admin={false} open={false} setOpen={function (open: boolean): void {
            throw new Error("Function not implemented.");
          } } />
          </PrivateRoute>
          <PrivateRoute path="/reports/subscription/:id/:sid">
            <SubscriptionWizard action="edit" admin={false} open={false} setOpen={function (open: boolean): void {
            throw new Error("Function not implemented.");
          } } />
          </PrivateRoute>
          <PrivateRoute path="/reports/subscription/:id">
            <SubscriptionWizard action="add" admin={false} open={false} setOpen={function (open: boolean): void {
            throw new Error("Function not implemented.");
          } } />
          </PrivateRoute>
          <PrivateRoute path="/reports/subscriptions/:rid">
            <MySubscriptions />
          </PrivateRoute>
          <PrivateRoute path="/reports/subscriptions">
            <MySubscriptions />
          </PrivateRoute>
          <PrivateRoute path="/reports/report/:id">
            <ReportForm action="edit" />
          </PrivateRoute>
          <PrivateRoute path="/reports/report:type?:category?">
            <ReportForm action="add" />
          </PrivateRoute>
          <PrivateRoute path="/reports/:id/:sid/:admin">
            <ViewReportPage />
          </PrivateRoute>
          <PrivateRoute path="/reports/:id">
            <RunReportPage />
          </PrivateRoute>
          <PrivateRoute path="/reports">
            <ReportCatalogPage />
          </PrivateRoute>
          <PrivateRoute path="/logout/:timeout?">
            <Logout />
          </PrivateRoute>
          <PrivateRoute
            path="/admin/subscription/:id/:sid/:filter"
            requireAdmin={true}
          >
            <SubscriptionWizard action="edit" admin={true} open={false} setOpen={function (open: boolean): void {
            throw new Error("Function not implemented.");
          } } />
          </PrivateRoute>
          <PrivateRoute path="/admin/subscription/:id/:sid" requireAdmin={true}>
            <SubscriptionWizard action="edit" admin={true} open={true} setOpen={function (open: boolean): void {
            throw new Error("Function not implemented.");
          } } />
          </PrivateRoute>
          <PrivateRoute path="/admin/subscriptions" requireAdmin={true}>
            <AdminSubscriptions />
          </PrivateRoute>
          <PrivateRoute path="/admin/settings" requireAdmin={true}>
            <SettingsForm />
          </PrivateRoute>
          <PrivateRoute path="/admin/userManagement" requireAdmin={true}>
            <UserManagementPage />
          </PrivateRoute>
          <PrivateRoute path="/forbidden">
            <ForbiddenPage />
          </PrivateRoute>
          <PrivateRoute>
            <NotFoundPage />
          </PrivateRoute>
          </Switch>
    )
}