import { acquireAccessToken } from "../azure/authToken";
import { ServerError, ServerErrorType } from "../redux/models/serverError";
import { DateUtils } from "./date.utils";

export const FetchUtils = {
  GET,
  GETFile,
  POST,
  PUT,
  DELETE
}


function GET<T>(url: string): Promise<T> {
  return acquireAccessToken().then((token) => {
    return fetch(url, GETHeaders(token))
      .then(handleResponse)
      .then((data: T) => {
        return data;
      })
      .catch(handleError);
  });
}

function GETFile(url: string): Promise<any> {
  return acquireAccessToken().then((token) => {
    return fetch(url, GETHeaders(token))
      .then(url.toLowerCase().includes("paystub")?handlePayStubResponseFile:handleResponseFile)
      .then((data: any | PromiseLike<any>) => {
        return data;
      })
      .catch(handleError);
  });
}

function POST<T>(url: string, data?: any): Promise<T> {
  return acquireAccessToken().then((token) => {
    return fetch(url, POSTHeaders(token, data))
      .then(handleResponse)
      .then((data: T) => {
        return data;
      })
      .catch(handleError);
  });
}

function PUT<T>(url: string, data?: any): Promise<T> {
  return acquireAccessToken().then((token) => {
    return fetch(url, PUTHeaders(token, data))
      .then(handleResponse)
      .then((data: T) => {
        return data;
      })
      .catch(handleError);
  });
}

function DELETE<T>(url: string, data?: any): Promise<T> {
  return acquireAccessToken().then((token) => {
    return fetch(url, DELETEHeaders(token, data))
      .then(handleResponse)
      .then((data: T) => {
        return data;
      })
      .catch(handleError);
  });
}

function GETHeaders(token?: string) {
  return {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };
}

function POSTHeaders(token?: string, data?: any) {
  return {
    method: 'POST',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: data ? JSON.stringify(data) : undefined
  };
}

function PUTHeaders(token?: string, data?: any) {
  return {
    method: 'PUT',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: data ? JSON.stringify(data) : undefined
  };
}

function DELETEHeaders(token?: string, data?: any) {
  return {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: data ? JSON.stringify(data) : undefined
  };
}

function handleResponse(response: Response) {
  if (response.ok) {
    return response.json();
  } else {
    try {
      return response.json().then((r) => {
        let f = r as ServerError;
        return Promise.reject(f);
      })
    }
    catch (e) {
      return Promise.reject({ errorType: ServerErrorType.Error, displayMessage: "An unexpected server error occured. Please try again later." } as ServerError);
    }
  }
}

function handlePayStubResponseFile(response: any) {
  if (response.ok) {
    response.json().then((data: any) => {
      let formattedStartDate = DateUtils.formatDateTime(new Date(`${data.exportStartDate}Z`));

      const filename = `${data.fileName}`;
      const bytes = _base64ToArrayBuffer(data.byteArray);
      var blob = new Blob([bytes], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename ?? 'Download';
      a.click();
      return blob;
    }

    );

  } else {
    return Promise.reject(`${response.status}`);
  }
}

function handleResponseFile(response: any) {
  if (response.ok) {
    response.json().then((data: any) => {
      let formattedStartDate = DateUtils.formatDateTime(new Date(`${data.exportStartDate}Z`));

      const filename = `${data.reportName}${data.reportSubtitle ? ` - ${data.reportSubtitle}` : ''}${data.exportStartDate ? ` ${formattedStartDate}` : ''}.${data.storageURL.split('.').pop()}`;
      const bytes = _base64ToArrayBuffer(data.reportByteArray);
      var blob = new Blob([bytes], { type: data.contentType });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename ?? 'Download';
      a.click();
      return blob;
    }

    );

  } else {
    return Promise.reject(`Status Code: ${response.status}`);
  }
}

function _base64ToArrayBuffer(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function handleError(error: TypeError | ServerError): Promise<never> {
  if (error instanceof TypeError) {
    let se: ServerError = {
      displayMessage: 'The server is down at the moment. Please try again later.',
      errorType: ServerErrorType.Warning
    }
    return Promise.reject(se)
  } else {
    return Promise.reject(error);
  }
}



