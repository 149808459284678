import { NetworkError, ServerError } from "../models/serverError";
import { PayStubActions } from "../actions/paystub.actions";

type PayStubState = {
    loading: boolean;
    loadingSubmit: boolean;
    error?: ServerError | NetworkError;
    payStubData?: any
}
const initialState: PayStubState = {
    loading: false,
    loadingSubmit: false,
    error: undefined,
    payStubData: undefined
}
const PayStubReducer = (state: PayStubState = initialState, action: PayStubActions): PayStubState => {
    switch (action.type) {
        case 'PAYSTUB_GET_PAYSTUB_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'PAYSTUB_GET_PAYSTUB_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                payStubData: action.response
            }
        case 'PAYSTUB_GET_PAYSTUB_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        
        default:
            return state;
    }
}
export default PayStubReducer;